import React from "react"
import GalleriPortratt from "../components/bilder/GalleriPortratt"

import Layout from "../components/layout"
import SEO from "../components/seo"

function IndexPage() {
  return (
    <Layout>
      <SEO
        isHome
        keywords={[`Jens Nordström`, `Fotograf`, `Malmö`]}
        title="Jens Nordström - Fotograf"
      />

      <section className="">
        <GalleriPortratt />
      </section>
    </Layout>
  )
}

export default IndexPage
